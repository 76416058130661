/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { findIndex } from "lodash";
import * as _ from "lodash";
import type { ResourceWithSlug } from "./ResourceWithSlug";
import type { ActionProperties } from "./actionProperties";
import type { DeploymentActionContainer } from "./deploymentActionContainer";
import type { FeedResource } from "./feedResource";
import { FeedType } from "./feedResource";
import type { GitDependencyReference } from "./gitDependencyReference";
import { PackageAcquisitionLocation } from "./packageAcquisitionLocation";
import type { PackageReference } from "./packageReference";
import { PackageSelectionMode } from "./packageReference";
import type { ResourceWithId } from "./resource";
import type { StepPackageInputs } from "./stepPackageInputs";
export interface DeploymentActionResource extends ResourceWithId, ResourceWithSlug {
    Id: string;
    Name: string;
    Notes: string | null;
    ActionType: string;
    WorkerPoolId: string | null;
    WorkerPoolVariable: string | null;
    Container: DeploymentActionContainer;
    IsDisabled: boolean;
    CanBeUsedForProjectVersioning: boolean;
    Environments: string[];
    ExcludedEnvironments: string[];
    Channels: string[];
    TenantTags: string[];
    Packages: PackageReference[];
    GitDependencies?: GitDependencyReference[];
    Properties: ActionProperties;
    StepPackageVersion?: string;
    Inputs?: StepPackageInputs | undefined;
    AvailableStepPackageVersions?: string[];
    IsRequired: boolean;
    Condition?: RunConditionForAction;
}
export enum RunConditionForAction {
    Success = "Success",
    Variable = "Variable"
}
export function IsDeployReleaseAction(action: DeploymentActionResource) {
    return !!action.Properties["Octopus.Action.DeployRelease.ProjectId"];
}
export function HasManualInterventionResponsibleTeams(action: DeploymentActionResource) {
    const responsibleTeamIds = action.Properties["Octopus.Action.Manual.ResponsibleTeamIds"];
    if (typeof responsibleTeamIds === "string") {
        return responsibleTeamIds.split(",").some(Boolean);
    }
    return false;
}
export function GetPrimaryPackageReference(packages: undefined | Array<PackageReference<any>>): PackageReference<any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return packages?.find((pkg) => IsPrimaryPackageReference(pkg))!;
}
export function IsPrimaryPackageReference(pkg: PackageReference<any>): boolean {
    return !pkg.Name;
}
export function IsNamedPackageReference(pkg: PackageReference): boolean {
    return !!pkg.Name;
}
export function GetNamedPackageReferences(packages: Array<PackageReference<any>>): Array<PackageReference<any>> {
    return RemovePrimaryPackageReference(packages);
}
export function GetPackageVariableNames(pkg: PackageReference<any>, feeds: FeedResource[]): string[] {
    return [
        `Octopus.Action.Package[${pkg.Name}].PackageId`,
        `Octopus.Action.Package[${pkg.Name}].FeedId`,
        `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
        `Octopus.Action.Package[${pkg.Name}].Path`,
        `Octopus.Action.Package[${pkg.Name}].SelectionMode`,
        ...GetDockerPackageVariableNames(pkg, feeds),
    ];
}
export function GetDockerPackageVariableNames(pkg: PackageReference<any>, feeds: FeedResource[]): string[] {
    const dockerFeeds = feeds.filter((f) => f.FeedType == FeedType.Docker).map((f) => f.Id);
    if (dockerFeeds.length == 0) {
        return [];
    }
    if (findIndex(dockerFeeds, (f) => f === pkg.FeedId, 0) >= 0) {
        return [`Octopus.Action.Package[${pkg.Name}].Image`, `Octopus.Action.Package[${pkg.Name}].Registry`, `Octopus.Action.Package[${pkg.Name}].Version`];
    }
    return [];
}
export function SetNamedPackageReference(name: string, updated: Partial<PackageReference<any>>, packages: Array<PackageReference<any>>): Array<PackageReference<any>> {
    return _.map(packages, (pkg) => {
        if (!PackageReferenceNamesMatch(name, pkg.Name!)) {
            return pkg;
        }
        return { ...pkg, ...updated };
    });
}
export function SetPrimaryPackageReference(updated: Partial<PackageReference<any>>, packages: Array<PackageReference<any>>): Array<PackageReference<any>> {
    return _.map(packages, (pkg) => {
        if (!IsPrimaryPackageReference(pkg)) {
            return pkg;
        }
        return { ...pkg, ...updated };
    });
}
export function InitialisePrimaryPackageReference(packages: Array<PackageReference<any>>, feeds: FeedResource[]): Array<PackageReference<any>> {
    const primaryPackage = GetPrimaryPackageReference(packages);
    if (primaryPackage) {
        if (!primaryPackage.Properties.SelectionMode) {
            primaryPackage.Properties.SelectionMode = PackageSelectionMode.Immediate;
        }
        return [...packages];
    }
    const packagesWithoutDefault = RemovePrimaryPackageReference(packages);
    const builtInFeed: FeedResource | undefined = feeds.find((f) => f.FeedType === FeedType.BuiltIn);
    const builtInFeedId: string | undefined = builtInFeed && builtInFeed.Id;
    return [
        {
            Id: null!,
            PackageId: null!,
            FeedId: builtInFeedId!,
            AcquisitionLocation: PackageAcquisitionLocation.Server,
            Properties: {
                SelectionMode: PackageSelectionMode.Immediate,
            },
        },
        ...packagesWithoutDefault,
    ];
}
export function RemovePrimaryPackageReference(packages: Array<PackageReference<any>>): Array<PackageReference<any>> {
    return _.filter(packages, (pkg) => !IsPrimaryPackageReference(pkg));
}
// Returns true if the names match, where null and empty string are equivalent
export function PackageReferenceNamesMatch(nameA: string | undefined, nameB: string | undefined): boolean {
    if (!nameA) {
        return !nameB;
    }
    return nameA === nameB;
}
export function InitialisePrimaryGitDependency(gitDependencies?: Array<GitDependencyReference>): GitDependencyReference[] {
    return _.concat(RemovePrimaryGitDependency(gitDependencies), [
        {
            Name: "",
            DefaultBranch: "main",
            GitCredentialType: "Library",
            RepositoryUri: "",
            FilePathFilters: null,
        },
    ]);
}
export function RemovePrimaryGitDependency(gitDependencies?: Array<GitDependencyReference>): GitDependencyReference[] {
    return _.filter(gitDependencies ?? [], (pkg) => pkg.Name !== "");
}
